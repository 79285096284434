import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import LockIcon from '@material-ui/icons/LockOutlined';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import AuthActions from '../../Redux/AuthRedux';

import Routes from '../../Routing/Routes';
import Seo from '../../Routing/Seo';

const useStyles = makeStyles(theme => ({
  layout: LayoutStyles.centered400Layout,
  paper: LayoutStyles.centered400Paper,
  avatar: {
    margin: theme.spacing(1, 1, 2),
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  pseudo: {
    marginTop: theme.spacing(3),
  },
  description: {
    marginTop: theme.spacing(2),
  },
}));

const ResetPassword = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const isFetching = useSelector(state => state.auth.isFetching);
  const [emailOrPseudo, setEmailOrPseudo] = React.useState('');
  const isPasswordToSet = location.pathname === '/passwords/set';

  const handleSubmit = event => {
    dispatch(AuthActions.resetPasswordRequest(emailOrPseudo));
    event.preventDefault();
  };

  return (
    <>
      <Helmet>
        {Seo.title(
          `${t(isPasswordToSet ? 'WELLO.SET_PASSWORD.TITLE' : 'WELLO.RESET_PASSWORD.TITLE')} ${t(
            `${Routes.currentNamespace()}.SEO.SUFFIX`,
          )}`,
        )}
        {Seo.description(`${t('WELLO.RESET_PASSWORD.DESCRIPTION')}`)}
        {Seo.noIndex()}
      </Helmet>
      <Grid container className={classes.layout}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="h5" align="center">
            {t(isPasswordToSet ? 'WELLO.SET_PASSWORD.TITLE' : 'WELLO.RESET_PASSWORD.TITLE')}
          </Typography>
          {isPasswordToSet && (
            <Typography variant="body2" align="center" className={classes.description}>
              {t('WELLO.SET_PASSWORD.DESCRIPTION')}
            </Typography>
          )}
          <form onSubmit={handleSubmit}>
            <TextField
              name="emailOrPseudo"
              value={emailOrPseudo}
              onChange={event => setEmailOrPseudo(event.target.value)}
              label={t('WELLO.RESET_PASSWORD.EMAIL')}
              InputProps={{
                autoComplete: 'email',
                autoFocus: true,
              }}
              margin="normal"
              fullWidth
              required
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isFetching}
            >
              {t(isPasswordToSet ? 'WELLO.SET_PASSWORD.SUBMIT' : 'WELLO.RESET_PASSWORD.SUBMIT')}
            </Button>
          </form>
          {/* <Typography component="h2" variant="subtitle1" className={classes.pseudo}>
              {t('WELLO.RESET_PASSWORD.PSEUDO')}
            </Typography> */}
        </Paper>
      </Grid>
    </>
  );
};

export default ResetPassword;
